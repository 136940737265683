// During Octopus deployment, variable values in this file using Octopus hash-syntax will be replaced
var BaseUrl = "#{TripFocus.ApiBaseUrl}";
var TripFocusUrl = "#{TripFocus.Url}";
var buildMode = "#{TripFocus.BuildMode}";

// For local development, the string above won't be replaced. The following block provides a default fallback URL
// In a file being ran through Octopus variable substitution, you have to avoid using the 'hash' symbol anywhere
// (other than a "real" Octopus variable, of course). Otherwise Octopus throws a wobbly.
if (~encodeURIComponent(buildMode).indexOf("%23%7B")) {
  //%23%7B = #{
  buildMode = "local"; //this will be set to prod in octopus
  TripFocusUrl = "http://localhost:8080/";
  BaseUrl = "https://api.dev.thekag.com/";
}

// export urls object including all available urls
let urls = {
  BaseUrl,
  TripFocusUrl,
  AuthenticationUrl: BaseUrl + "identityserver/v1/users/login",
  IdentityServerBaseUrl: BaseUrl + "identityserver/",
  LoadTendersUrl: BaseUrl + "loadtenders/v1",

  // OrdersUrl: BaseUrl + "orders/v1", //used for posts until DAL is replaced //12-02-2022 this is not used anywhere - JEM
  OrdersV2Url: BaseUrl + "orders/v2", //used for gets right now.

  LoadTenderTemplateUrl: BaseUrl + "loadtenders/v1/templates",

  ImagePermissionsUrl: BaseUrl + "ImagePortalApi/v1/DocTypes",
  ImagePortalUrl: BaseUrl + "ImagePortalApi/v1",

  NotificationsBaseUrl: BaseUrl + "Notifications/v1",
  NotificationSubscriptionsUrl:
    BaseUrl + "Notifications/v1/NotificationSubscriptions",
  NotificationEventTypesUrl:
    BaseUrl + "Notifications/v1/NotificationEventTypes",

  LocationsUrl: BaseUrl + "locations/locations/v1",

  CustomersUrl: BaseUrl + "Customers/Customer",
  CustomerPermissionsUrl:
    BaseUrl + "Customers/v1/CustomerPermissions",

  UsersUrl: BaseUrl + "identityserver/v1/users",
  ProfileUrl: BaseUrl + "identityserver/v1/profile",
  PermissionsUrl: BaseUrl + "identityserver/v1/UserPermissions",

  MasterPermissionsBaseUrl: BaseUrl + "identityserver/v1",
  RelationshipsBaseUrl: BaseUrl + "Customers", //dev
  //RelationshipsBaseUrl : "https://localhost:44314/v3", //local

  CommoditiesBaseUrl: BaseUrl + "commodities/v1",
  CompaniesBaseUrl: BaseUrl + "companies/v1",
  LoggingUrl: BaseUrl + "LoggingCore/v2/Events",
	CustomerReferenceNumberTypeUrl: `${BaseUrl}Customers/CustomerRefNumType`,
	CustomerUserReferenceNumberTypeUrl: `${BaseUrl}Customers/CustomerUserRefNumType`
};

//override urls here if you want to use something other than what's above.
if (buildMode === "local") {
	// urls.LoadTendersUrl = "https://localhost:53048/v1";
	// urls.CustomerReferenceNumberTypeUrl = "https://localhost:63400/CustomerRefNumType";
	// urls.CustomerUserReferenceNumberTypeUrl = "https://localhost:63400/CustomerUserRefNumType";
	// urls.LoadTenderTemplateUrl = "https://localhost:53048/v1/Templates";
	// urls.RelationshipsBaseUrl = "https://localhost:63400"; //local
	// urls.OrdersV2Url = "https://localhost:56758";
	// urls.CompaniesBaseUrl = "https://localhost:58088/v1";
	// urls.LocationsUrl = "https://localhost:51748/locations/v1"
	// urls.CustomersUrl = "https://localhost:63400/Customer"
	// urls.CustomerPermissionsUrl = "https://localhost:63400/v1/CustomerPermissions"
	// urls.ImagePortalUrl = "https://localhost:58341/v1"
	// urls.IdentityServerBaseUrl = "https://localhost:61368"
	// urls.NotificationSubscriptionsUrl = "https://localhost:53251/NotificationSubscriptions"
	// urls.NotificationsBaseUrl = "https://localhost:53251"
	// urls.LoggingUrl = "https://localhost:56072/v2/Events"
}

export default {
  ...urls
};
